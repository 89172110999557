/* You can add global styles to this file, and also import other style files */

body {
  margin: 0;
  line-height: normal;
  scroll-behavior: smooth;
}

.read-reflect {
  position: absolute;
  top: 246px;
  left: 84px;
  font-size: 45px;
  line-height: 37px;
  font-weight: 600;
}

.import-kindle-highlights {
  position: absolute;
  top: 1173px;
  left: 106px;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
}

.highlights {
  position: absolute;
  top: 63px;
  left: 124px;
  font-size: 30px;
  line-height: 34px;
  font-weight: 600;
}
.blog {
  position: absolute;
  top: 63px;
  left: 1134px;
  font-size: 30px;
  line-height: 34px;
}
.download {
  position: absolute;
  top: 63px;
  left: 1224px;
  font-size: 30px;
  line-height: 34px;
}
.simply-reading-isnt {
  position: absolute;
  top: 411px;
  left: 84px;
  font-size: 30px;
  letter-spacing: 0.02em;
  line-height: 38px;
  font-weight: 300;
  color: #a8917b;
  display: inline-block;
  width: 562px;
}
.b-3-2 {
  position: absolute;
  top: 172px;
  left: 821px;
  width: 519px;
  height: 519px;
  object-fit: cover;
}
.landingpage-child {
  position: absolute;
  top: 600px;
  left: 84px;
  border-radius: 100px;
  background-color: rgba(255, 254, 254, 0);
  border: 2px solid #5d3a15;
  box-sizing: border-box;
  width: 196px;
  height: 71px;
}
.download1 {
  position: absolute;
  top: 616px;
  left: 125px;
  font-size: 24px;
  line-height: 37px;
  font-family: 'Helvetica Neue';
}
.landingpage-item {
  position: absolute;
  top: 923.5px;
  left: 83.5px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 1253px;
  height: 1px;
}
.landingpage-inner {
  position: absolute;
  top: 1114px;
  left: 106px;
  border-radius: 50%;
  background-color: #5d3a15;
  width: 37px;
  height: 37px;
}
.b {
  position: absolute;
  top: 1115px;
  left: 120px;
  font-size: 25px;
  line-height: 37px;
  color: #fff;
}
.reflect {
  position: absolute;
  top: 1173px;
  left: 540px;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
}

.ellipse-div {
  position: absolute;
  top: 1114px;
  left: 540px;
  border-radius: 50%;
  background-color: #5d3a15;
  width: 37px;
  height: 37px;
}
.b1 {
  position: absolute;
  top: 1115px;
  left: 551px;
  font-size: 25px;
  line-height: 37px;
  color: #fff;
}
.remember {
  position: absolute;
  top: 1173px;
  left: 997px;
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
}
.landingpage-child1 {
  position: absolute;
  top: 1114px;
  left: 997px;
  border-radius: 50%;
  background-color: #5d3a15;
  width: 37px;
  height: 37px;
}
.b2 {
  position: absolute;
  top: 1115px;
  left: 1009px;
  font-size: 25px;
  line-height: 37px;
  color: #fff;
}
.line-div {
  position: absolute;
  top: 937.5px;
  left: 503.5px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 1px;
  height: 1035px;
}
.landingpage-child2 {
  position: absolute;
  top: 937.5px;
  left: 960.5px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 1px;
  height: 1035px;
}
.image-30-icon {
  position: absolute;
  top: 1730px;
  left: 150px;
  width: 168px;
  height: 168px;
  object-fit: cover;
}
.image-31-icon {
  position: absolute;
  top: 1707px;
  left: 637px;
  width: 191px;
  height: 191px;
  object-fit: cover;
}
.image-32-icon {
  position: absolute;
  top: 1724px;
  left: 1101px;
  width: 181px;
  height: 181px;
  object-fit: cover;
}
.rectangle-div {
  position: absolute;
  top: 2025px;
  left: 0px;
  background-color: #feb3c8;
  width: 1440px;
  height: 1024px;
}
.landingpage-child3 {
  position: absolute;
  top: 3049px;
  left: 0px;
  background-color: #050142;
  width: 1440px;
  height: 1024px;
}
.landingpage-child4 {
  position: absolute;
  top: 4073px;
  left: 0px;
  background-color: #fce38f;
  width: 1440px;
  height: 1024px;
}
.landingpage-child5 {
  position: absolute;
  top: 5097px;
  left: 0px;
  background-color: #e7f3fe;
  width: 1440px;
  height: 1024px;
}
.landingpage-child6 {
  position: absolute;
  top: 6121px;
  left: 0px;
  background-color: #f49660;
  width: 1440px;
  height: 1024px;
}
.reading-a-book {
  position: absolute;
  top: 2140px;
  left: 88px;
  font-size: 60px;
  letter-spacing: 0.02em;
  line-height: 56px;
  font-weight: 500;
  color: #610033;
  display: inline-block;
  width: 626px;
}
.image-29-icon {
  position: absolute;
  top: 2176px;
  left: 896px;
  width: 409.07px;
  height: 735.48px;
  object-fit: contain;
  transform: rotate(3.88deg);
}
.image-25-icon {
  position: absolute;
  top: 3237px;
  left: 173.61px;
  width: 358.39px;
  height: 638.83px;
  object-fit: contain;
  transform: rotate(4.58deg);
}
.image-26-icon {
  position: absolute;
  top: 3257.07px;
  left: 56px;
  width: 350.1px;
  height: 620.43px;
  object-fit: contain;
  transform: rotate(4.3deg);
}
.rethink-the-way {
  position: absolute;
  top: 3325px;
  left: 720px;
  font-size: 60px;
  letter-spacing: 0.02em;
  line-height: 56px;
  font-weight: 500;
  color: #c3e2ef;
  display: inline-block;
  width: 592px;
}
.highlights-app-makes {
  margin: 0;
}
.highlights-app-makes-container {
  position: absolute;
  top: 3518px;
  left: 720px;
  font-size: 34px;
  letter-spacing: 0.02em;
  line-height: 45px;
  font-weight: 300;
  color: #c3e2ef;
  display: inline-block;
  width: 592px;
}
.image-25-icon1 {
  position: absolute;
  top: 4229px;
  left: 887px;
  width: 392px;
  height: 718px;
  object-fit: cover;
}
.track-your-reading {
  position: absolute;
  top: 4296px;
  left: 127px;
  font-size: 60px;
  line-height: 56px;
  font-weight: 500;
  color: #167c3f;
  display: inline-block;
  width: 625px;
  height: 175px;
}
.define-your-reading {
  position: absolute;
  top: 4544px;
  left: 127px;
  font-size: 34px;
  line-height: 45px;
  font-weight: 300;
  color: #167c3f;
  display: inline-block;
  width: 565px;
}
.image-25-icon2 {
  position: absolute;
  top: 5330px;
  left: 93px;
  width: 281px;
  height: 561px;
  object-fit: cover;
}
.image-26-icon1 {
  position: absolute;
  top: 5304px;
  left: 200px;
  width: 338px;
  height: 619px;
  object-fit: cover;
}
.book-shots {
  position: absolute;
  top: 5242px;
  left: 640px;
  font-size: 60px;
  line-height: 56px;
  font-weight: 500;
  color: #6f45d6;
}
.key-insights-actionable {
  position: absolute;
  top: 5346px;
  left: 640px;
  font-size: 34px;
  line-height: 45px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 546px;
}
.heres-everything-that {
  position: absolute;
  top: 5529px;
  left: 640px;
  font-size: 24px;
  text-decoration: underline;
  line-height: 37px;
  font-weight: 500;
  font-family: 'Helvetica Neue';
  color: #6f45d6;
}
.checkmark-icon {
  position: absolute;
  top: 5609px;
  left: 629px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.kindle-highlights-sync {
  position: absolute;
  top: 5623px;
  left: 691px;
  font-size: 22px;
  font-weight: 300;
  color: #6f45d6;
}
.checkmark-icon1 {
  position: absolute;
  top: 5609px;
  left: 1036px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.collect-cherished-highlights {
  position: absolute;
  top: 5614px;
  left: 1095px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 254px;
  height: 41px;
}
.checkmark-icon2 {
  position: absolute;
  top: 5698px;
  left: 1036px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.edit-share {
  position: absolute;
  top: 5701px;
  left: 1095px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 222px;
  height: 38px;
}
.checkmark-icon3 {
  position: absolute;
  top: 5786px;
  left: 1036px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.export-all-highlights {
  position: absolute;
  top: 5785px;
  left: 1095px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 280px;
  height: 59px;
}
.checkmark-icon4 {
  position: absolute;
  top: 5907px;
  left: 629px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.checkmark-icon5 {
  position: absolute;
  top: 5673px;
  left: 629px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.notification-reminders {
  position: absolute;
  top: 5687px;
  left: 691px;
  font-size: 22px;
  font-weight: 300;
  color: #6f45d6;
}
.checkmark-icon6 {
  position: absolute;
  top: 5737px;
  left: 629px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.set-reading-goals {
  position: absolute;
  top: 5751px;
  left: 691px;
  font-size: 22px;
  font-weight: 300;
  color: #6f45d6;
}
.checkmark-icon7 {
  position: absolute;
  top: 5811px;
  left: 629px;
  width: 36px;
  height: 41px;
  object-fit: cover;
}
.track-your-reading1 {
  position: absolute;
  top: 5814px;
  left: 691px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 276px;
  height: 41px;
}
.key-insights-actionable1 {
  position: absolute;
  top: 5903px;
  left: 691px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 300;
  color: #6f45d6;
  display: inline-block;
  width: 322px;
  height: 100px;
}
.image-33-icon {
  position: absolute;
  top: 63px;
  left: 84px;
  width: 24.82px;
  height: 34px;
  object-fit: cover;
}
.frequently-asked-questions {
  position: absolute;
  top: 6223px;
  padding-bottom: 20px;
  left: 97px;
  font-size: 60px;
  letter-spacing: 0.02em;
  line-height: 37px;
  font-weight: 500;
  color: #ffeaa5;
}
.start-reading-with {
  position: absolute;
  top: 7338px;
  left: 113px;
  font-size: 80px;
  line-height: 85px;
  display: inline-block;
  font-family: 'Gentium Book Basic';
  width: 540px;
}
.landingpage-child7 {
  position: absolute;
  top: 7424px;
  left: 715px;
  border-radius: 10px;
  background-color: #2b2d31;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 289px;
  height: 83px;
}
.apple-app-store {
  position: absolute;
  top: 7468px;
  left: 816px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  width: 161px;
  height: 19px;
}
.download-from {
  position: absolute;
  top: 7445px;
  left: 816px;
  font-family: Inter;
  color: #fff;
  display: inline-block;
  width: 158px;
  height: 18px;
}
.landingpage-child8 {
  position: absolute;
  top: 7273px;
  left: 60px;
  border-radius: 10px;
  background-color: rgba(217, 217, 217, 0);
  border: 1px solid rgba(93, 58, 21, 0.8);
  box-sizing: border-box;
  width: 1307px;
  height: 385px;
}
.apple-logo-icon {
  position: absolute;
  top: 7444px;
  left: 745px;
  width: 48px;
  height: 43px;
  object-fit: contain;
}
.landingpage-child9 {
  position: absolute;
  top: 7424px;
  left: 1025px;
  border-radius: 10px;
  background-color: #2b2d31;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 290px;
  height: 83px;
}
.google-play-store {
  position: absolute;
  top: 7469px;
  left: 1106px;
  display: inline-block;
  font-family: Inter;
  color: #fff;
  width: 189px;
  height: 18px;
}
.download-from1 {
  position: absolute;
  top: 7445px;
  left: 1106px;
  font-family: Inter;
  color: #fff;
  display: inline-block;
  width: 158px;
  height: 19px;
}
.google-play-icon {
  position: absolute;
  top: 7445px;
  left: 1044px;
  width: 48px;
  height: 43px;
  object-fit: contain;
}
.made-with {
  font-weight: 300;
  font-family: 'League Spartan';
}
.truejishnu {
  text-decoration: underline;
}
.made-with-container {
  position: absolute;
  top: 7807px;
  left: 577px;
  font-size: 25px;
  line-height: 37px;
}
.highlights1 {
  position: absolute;
  top: 7951px;
  left: 60px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.all-rights-reserved {
  position: absolute;
  top: 7985px;
  left: 60px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.bolg {
  position: absolute;
  top: 7985px;
  left: 456px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.download2 {
  position: absolute;
  top: 7985px;
  left: 720px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.terms-of-use {
  position: absolute;
  top: 7985px;
  left: 966px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.privacy-policy {
  position: absolute;
  top: 7979px;
  left: 1236px;
  line-height: 34px;
  font-family: 'Helvetica Neue';
}
.image-36-icon {
  position: absolute;
  top: 7511px;
  left: 0px;
  width: 694px;
  height: 102px;
  object-fit: cover;
}
.image-37-icon {
  position: absolute;
  top: 948px;
  left: 93px;
  width: 36px;
  height: 35px;
  object-fit: cover;
}
.scroll {
  position: absolute;
  top: 745px;
  left: 1314px;
  font-size: 30px;
  line-height: 37px;
  font-weight: 300;
  color: #000;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.sync-your-highlights {
  position: absolute;
  top: 1247px;
  left: 106px;
  font-size: 25px;
  line-height: 37px;
  font-weight: 300;
  display: inline-block;
  width: 365px;
}
@media only screen and (max-width: 700px) {
  .sync-your-highlights {
    position: absolute;
    top: 1247px;
    left: 106px;
    font-size: 25px;
    line-height: 37px;
    font-weight: 400;
    display: inline-block;
    width: 365px;
  }
}
.tip-additionally-you {
  position: absolute;
  top: 1430px;
  left: 106px;
  font-size: 25px;
  line-height: 37px;
  font-weight: 300;
  display: inline-block;
  width: 344px;
}
.easily-access-the {
  position: absolute;
  top: 1247px;
  left: 543px;
  font-size: 25px;
  line-height: 37px;
  font-weight: 300;
  display: inline-block;
  width: 392px;
}
.enhance-your-knowledge {
  position: absolute;
  top: 1247px;
  left: 1004px;
  font-size: 25px;
  line-height: 37px;
  font-weight: 300;
  display: inline-block;
  width: 343px;
}
.your-formula-for {
  margin: 0;
  font-weight: 300;
}
.your-formula-for-container {
  position: absolute;
  top: 307px;
  left: 84px;
  font-size: 40px;
  line-height: 40px;
}
@media only screen and (max-width: 414px) {
  .your-formula-for-container {
    position: absolute;
    top: 307px;
    left: 84px;
    font-size: 50px;
    line-height: 40px;
    margin-top: 0px;
  }
}

.right-arrow-icon {
  position: absolute;
  top: 820px;
  left: 1278px;
  width: 36px;
  height: 36px;
  z-index: 1;
  object-fit: cover;
}
.the-daily-review-container {
  position: absolute;
  top: 2377px;
  left: 88px;
  font-size: 34px;
  letter-spacing: 0.02em;
  line-height: 45px;
  font-weight: 300;
  color: #610033;
  display: inline-block;
  width: 634px;
}
.streak-calendar-see-container {
  position: absolute;
  top: 2786px;
  left: 88px;
  font-size: 34px;
  letter-spacing: 0.02em;
  line-height: 45px;
  font-weight: 300;
  color: #610033;
}
.method {
  position: absolute;
  top: 949px;
  left: 144px;
  font-size: 45px;
  line-height: 37px;
  font-weight: 300;
}
.landingpage {
  position: relative;
  background-color: #fff9f2;
  width: 110%;
  height: 8051px;
  overflow: hidden;
  /* overflow-x: hidden; */
  text-align: left;
  font-size: 16px;
  color: #5d3a15;
  font-family: 'League Spartan';
  box-sizing: border-box;
}
@media only screen and (max-width: 700px) {
  .landingpage {
    position: relative;
    background-color: #fff9f2;
    width: 110%;
    height: 8051px;
    overflow: hidden;
    /* overflow-x: hidden; */
    text-align: left;
    font-size: 16px;
    color: #5d3a15;
    font-family: 'League Spartan';
    box-sizing: border-box;
  }
}

.what-is-kindle {
  margin-block-start: 0;
  margin-block-end: 55px;
}
.how-can-i {
  margin: 0;
}
.what-is-kindle-container {
  position: relative;
  font-size: 45px;
  font-family: 'League Spartan';
  color: #ffeaa5;
  text-align: left;
}

input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.accordion-wrapper {
  border-radius: 8px;
  overflow: hidden;

  width: 830px;
  margin-top: 70px;
}
.accordion {
  width: 100%;
  color: white;
  overflow: hidden;
  margin-bottom: 16px;
}
.accordion:last-child {
  margin-bottom: 0;
}
.accordion-label {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;

  font-weight: normal;
  cursor: pointer;
  font-size: 30px;
}
.accordion-label:hover {
  cursor: pointer;
  color: #ffeaa5;
}
.accordion-label::after {
  content: '\276F';
  width: 16px;
  height: 16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content {
  max-height: 0;
  padding: 0 16px;
  color: #000;

  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content p {
  margin: 0;

  font-size: 18px;
}

input:checked + .accordion-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
input:checked ~ .accordion-content {
  max-height: 100vh;
  padding: 16px;
}

@media (max-width: 768px) {
  .text-base {
    font-size: 1.3rem; /* Adjust the font size for mobile screens */
  }
}


